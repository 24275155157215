import React from "react";
import { IActionButtonProps } from "./interface";


import styles from "./styles.module.scss";

const ActionButton =({
  children,
  isDisabled,
  onClick,
}: IActionButtonProps)=> {
  return (
    <button className={styles.button_action} disabled={isDisabled} onClick={onClick}>
      {children}
    </button>
  );
}

export default React.memo(ActionButton)
