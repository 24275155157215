import coin from "@/assets/icons/coin.png";
import pearl from "@/assets/icons/pearls.png";
import done from "@/assets/icons/done.png";

export const formatPrize = (coins: string, pearl: string) => {
  const validateText = "0 золота";
  return coins == validateText ? pearl : coins;
};

export const formatImagePrize = (prize: string, status: boolean) => {
  // if (status) return done;
  return prize.toLowerCase().includes("золота") ? coin : pearl;
};
