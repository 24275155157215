import { createSlice } from "@reduxjs/toolkit";
import { IQuestsState } from "./interfaces";
import { IQuest } from "@/interfaces/interfaces";
import rectangle from "@/assets/figures/rectangle.jpg";
import { formatImagePrize, formatPrize } from "@/utils/formatters/formatPrize";
import { STATUS_QUEST } from "./quests.constants";
import { getImageByType } from "@/utils/quest/getImageByType";

const initialState: IQuestsState = {
  quests: [],
};

export const questSlice = createSlice({
  name: "quests",
  initialState,
  reducers: {
    setQuests: (state, action) => {
      state.quests = action.payload.map((quest: IQuest) => {
        const formattedPrize = formatPrize(quest.coins, quest.pearls);
        const status = quest.actions[0].status == STATUS_QUEST.COMPLETED;
        const isInvite = quest.task_type == "invite";

        const formatUrl = (url: string) => {
          if (url.startsWith("t.me")) {
            return "https://" + url;
          }
          return url;
        };

        return {
          id: quest.actions[0].id,
          title: quest.name || "Unknown Quest",
          prize: formattedPrize,
          link: formatUrl(quest.url) || "/",
          progress: quest.actions[0].progress,
          target: isInvite ? quest.target : 0,
          isInvite: isInvite,
          imgPrize: formatImagePrize(formattedPrize, status),
          img: getImageByType(quest.icon_type),
          status: status,
          task_type:quest.task_type
        };
      });
    },
    updateFriendQuest: (state, action) => {
      if (!state.quests) return;

      const inviteQuest = state.quests.find((task) => task.isInvite === true);

      if (inviteQuest) {
        inviteQuest.progress = action.payload.progress;

        if (action.payload.progress === 10 && state.quests[3]) {
          state.quests[3].status = true;
        }
      }
    },
    setCompleteQuest: (state, action) => {
      const quest = state.quests.find((q) => q.id == action.payload);
      if (quest) {
        quest.status = true;
      }
    },
  },
});

export const { setQuests, setCompleteQuest, updateFriendQuest } =
  questSlice.actions;
export default questSlice.reducer;
