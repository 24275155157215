import { createContext, useContext, useState, ReactNode } from "react";
import PopUp from "@/components/Popup";
import { StaticImageData } from "next/image";

interface PopupContextProps {
  modal: (
    title: string,
    textButton: string,
    text?: string,
    icon?: StaticImageData,
    values?: IValue
  ) => Promise<boolean>;
}

export interface IValue {
  textValue: string;
  values: {
    pearls: number;
    coins: number;
  };
}

const PopupContext = createContext<PopupContextProps | undefined>(undefined);

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error(
      "Popup должен быть внутри <PopupProvider>{chilldren}</PopupProvider>"
    );
  }
  return context;
};

export const PopupProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState<string>();
  const [icon, setIcon] = useState<StaticImageData>();
  const [values, setValues] = useState<IValue>();
  const [textButton, setTextButton] = useState("");
  const [resolveCallback, setResolveCallback] = useState<
    ((result: boolean) => void) | null
  >(null);

  const modal = (
    title: string,
    textButton: string,
    text?: string,
    icon?: StaticImageData,
    values?: IValue
  ): Promise<boolean> => {
    setTextButton(textButton);
    setIsOpen(true);
    setTitle(title);
    setText(text);
    setIcon(icon);
    setValues(values);
    return new Promise((resolve) => {
      setResolveCallback(() => resolve);
    });
  };

  const handleClose = (result: boolean) => {
    setIsOpen(false);
    if (resolveCallback) {
      resolveCallback(result);
    }
  };

  const PopUpModal = () => (
    <PopUp
      isOpen={isOpen}
      textButton={textButton}
      onClose={handleClose}
      text={text}
      icon={icon}
      values={values}
      title={title}
    />
  );

  return (
    <PopupContext.Provider value={{ modal }}>
      {children}
      <PopUpModal />
    </PopupContext.Provider>
  );
};
