import speed1 from "@/assets/screens/shop/icon_speedUp_1.png";
import speed2 from "@/assets/screens/shop/icon_speedUp_2.png";
import speed3 from "@/assets/screens/shop/icon_speedUp_3.png";
import speed4 from "@/assets/screens/shop/icon_speedUp_4.png";
import buster1 from "@/assets/screens/shop/icon_autoGathering_1.png";
import buster2 from "@/assets/screens/shop/icon_autoGathering_2.png";
import buster3 from "@/assets/screens/shop/icon_autoGathering_3.png";
import buster4 from "@/assets/screens/shop/icon_autoGathering_4.png";
import pearl1 from "@/assets/screens/shop/icon_pearl_1.png";
import pearl2 from "@/assets/screens/shop/icon_pearl_2.png";
import pearl3 from "@/assets/screens/shop/icon_pearl_3.png";
import pearl4 from "@/assets/screens/shop/icon_pearl_4.png";
import { IShopItem, IShopItemDTO } from "@/store/shop/interfaces";

export const getShopItemImage = (image: string) => {
  switch (image) {
    case "speed1":
      return speed1;
    case "speed2":
      return speed2;
    case "speed3":
      return speed3;
    case "speed4":
      return speed4;
    case "buster1":
      return buster1;
    case "buster2":
      return buster2;
    case "buster3":
      return buster3;
    case "buster4":
      return buster4;
    case "pearl1":
      return pearl1;
    case "pearl2":
      return pearl2;
    case "pearl3":
      return pearl3;
    case "pearl4":
      return pearl4;
    default:
      return speed1;
  }
};

export const getSection = (currentPage: string) => {
  switch (currentPage) {
    case "ЖЕМЧУГ":
      return "pearls";
    case "БУСТЕРЫ":
      return "boosters";
    default:
      return "boosters";
  }
};

export const proccessShopItems = (items: IShopItemDTO[]):IShopItem[] => {
  return items.map((item) => ({
    ...item,
    image: getShopItemImage(item.image),
    
  }));
};
