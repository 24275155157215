import { createSlice } from "@reduxjs/toolkit";
import { IUserSlice } from "./interfaces";
import { USER } from "./user.mockData";
import { Address } from "@ton/ton";
import { isTestnet } from "../../../localConfig";

const initialState: IUserSlice = USER;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.id = action.payload.user_id;
      state.ref = action.payload.start_param;
      state.username = action.payload.username;
      state.is_premium = action.payload.is_premium;
    },
    setLastOpenedChestIndex: (state, action) => {
      state.balance.lastOpenedChestIndex = action.payload;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    setBoosterID: (state, action) => {
      state.balance.multipliers_boosters.boosterID = action.payload;
    },
    decreaseBoosterTime: (state, action) => {
      const remainingTime = action.payload.time_remaing;

      if (state.balance.multipliers_boosters) {
        state.balance.multipliers_boosters.time_remaing = remainingTime;
      }
    },

    setBoosters: (state, action) => {
      state.balance.auto_boosters.quantity =
        action.payload.auto_boosters.quantity || 0;
      state.balance.multipliers_boosters.booster =
        action.payload.multipliers_boosters.booster || 0;
      state.balance.multipliers_boosters.time_remaing =
        action.payload.multipliers_boosters.time_remaing || "";
    },

    resetBooster: (state, action) => {
      const { booster } = action.payload;
      if (state.balance.multipliers_boosters) {
        state.balance.multipliers_boosters.booster = booster;
        state.balance.multipliers_boosters.time_remaing = 0;
      }
    },

    updateBalance: (state, action) => {
      state.balance.coins = action.payload.coins;
      state.balance.pearls = action.payload.pearls;
      state.balance.ton = action.payload.ton;
      state.balance.keys_cheast = action.payload.keys_chest;
    },
    increaseCoins: (state, action) => {
      state.balance.coins += action.payload;
    },
    increasePearls: (state, action) => {
      state.balance.pearls += action.payload;
    },
    increaseAutoBoostersUser: (state, action) => {
      state.balance.auto_boosters.quantity += action.payload;
    },
    increaseKeysChests: (state, action) => {
      state.balance.keys_cheast += action.payload;
    },
    decreaseAutoBoostersUser: (state, action) => {
      state.balance.auto_boosters.quantity -= action.payload;
    },
    decreaseCoins: (state, action) => {
      state.balance.coins -= action.payload;
    },

    decreasePearls: (state, action) => {
      state.balance.pearls -= action.payload;
    },
    decreaseKeysChests: (state, action) => {
      state.balance.keys_cheast -= action.payload;
    },
    setCheasts: (state, action) => {
      state.chests = action.payload;
    },
    setWallet: (state, action) => {
      if (action.payload?.account) {
        state.wallet = {
          ...action.payload,
          account: {
            ...action.payload.account,
            publicKey: Address.parse(action.payload.account.address).toString({
              urlSafe: true,
              bounceable: false,
              testOnly: isTestnet ? true : false,
            }),
          },
        };
      } else {
        state.wallet = null;
      }
    },
    setFirstEperience: (state, action) => {
      state.firstExperience = action.payload;
    },
  },
});

export const {
  setUser,
  increaseCoins,
  increasePearls,
  decreaseCoins,
  setWallet,
  increaseAutoBoostersUser,
  decreaseAutoBoostersUser,
  decreasePearls,
  updateBalance,
  setCheasts,
  setBoosterID,
  increaseKeysChests,
  setBoosters,
  setStats,
  decreaseKeysChests,
  decreaseBoosterTime,
  resetBooster,
  setFirstEperience,
  setLastOpenedChestIndex,
} = userSlice.actions;
export default userSlice.reducer;
