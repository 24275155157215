import { configureStore } from "@reduxjs/toolkit";
import hudReducer from "./hud/hudSlice";
import questsReducer from "./quests/questsSlice";
import leaderboardReducer from "./leaderboard/leaderboardSlice";
import islandReducer from "./island/islandSlice";
import appReducer from "./app/appSlice";
import userReducer from "./user/userSlice";
import socketsReducer from "./sockets/soketsSlice";
import shopReducer from "./shop/shopSlice";
import tutorialReducer from "./tutorial/tutorialSlice";

export const store = configureStore({
  reducer: {
    hud: hudReducer,
    quests: questsReducer,
    leaderboard: leaderboardReducer,
    island: islandReducer,
    app: appReducer,
    user: userReducer,
    sockets: socketsReducer,
    shop: shopReducer,
    tutorial: tutorialReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
